<div class="container">
  <div class="text">
    <h1>Für noch mehr Komfort <span>„NMEA Connect“</span></h1>
    <p>NMEA Connect ist die Schnittstelle zu unserem Voyage Data Recorder (VDR) für Sportboote. Sobald das Schiff seine Position um wenige Meter ändert, zeichnet der VDR alle im NMEA Netzwerk verfügbaren Daten wie zb. Kurs, Geschwindigkeit, Position, Wassertiefe, Windstärke - Richtung,... vollautomatisch in frei definierbaren Zeitabständen auf. Diese Daten werden an Bord und zusätzlich, sobald ein Mobilfunknetz verfügbar ist, in der Cloud auf Deutschen Servern gesichert. Im Falle einer Havarie oder eines Diebstahls wichtige Beweismittel. </p>
    <h2 *ngIf="hasBetaAccess">Du hast einen SailServer?</h2>
    <p *ngIf="hasBetaAccess">Dann hast du die Möglichkeit diesen mit DiLog zu verbinden und deine Aufzeichnungen in dein Logbuch einfließen zu lassen.</p>
    <p *ngIf="hasBetaAccess">Du hast aktuell <b [innerText]="sailServers.length"></b> SailServer verbunden.</p>
    <p-button *ngIf="hasBetaAccess" (onClick)="connectModalVisibleChanged.emit(true)" label="SailServer Connect" />
  </div>
  <img class="img" src="assets/blackbox.jpeg">
</div>

