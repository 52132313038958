import {Component, OnInit} from '@angular/core';
import {NgClass, NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {AppService} from "../app.service";
import {Router, RouterLink} from "@angular/router";
import moment from "moment";
import {LogPrefix} from "../app.config";
import {ButtonModule} from "primeng/button";
import {TooltipModule} from "primeng/tooltip";
import {NmeaConnectPreviewComponent} from "../nmea-connect-preview/nmea-connect-preview.component";
import {
  Track,
  UserDetails,
  TracksService,
  BaseService,
  SailServerService, SailServer, StringHelper, InfoService
} from "logbuch-client-sdk";
import {Message} from "primeng/message";
import {Dialog} from "primeng/dialog";
import {InputText} from "primeng/inputtext";
import validator from "validator";
import {AlertModalService, AlertType} from "../alert-modal/alert-modal.service";
import {ProgressSpinner} from "primeng/progressspinner";
import {LoaderService} from "../loader/loader.service";

@Component({
  selector: 'app-tracks',
  imports: [
    NgForOf,
    NgClass,
    FormsModule,
    NgIf,
    ButtonModule,
    TooltipModule,
    NmeaConnectPreviewComponent,
    Dialog,
    InputText,
  ],
  templateUrl: './tracks.component.html',
  standalone: true,
  styleUrl: './tracks.component.scss'
})
export class TracksComponent implements OnInit {

  user?: UserDetails;
  tracks: Track[] = [];
  sailServers: SailServer[] = [];
  sortedList: TrackList[] = [];

  flagDict: { [key: string]: string } = {};

  page = 1;
  take = 100;
  search = '';

  connectModalVisible = false;

  sailServerApiKey = '';

  get gateway() {
    return this.user?.gateways.find(g => g.id === this.base.selectedGateway)
      || this.user?.permitted_gateways.find(g => g.id === this.base.selectedGateway);
  }

  get foreignGateway() {
    return this.sailServers.find(g => g.id === this.base.selectedSailServer);
  }

  get possibleGateways() {
    if (this.user === undefined) {
      return [];
    }
    return this.user?.gateways.concat(this.user.permitted_gateways);
  }

  get isBusy() {
    return this.loader.visibility;
  }

  constructor(private appService: AppService,
              private router: Router,
              private loader: LoaderService,
              private trackService: TracksService,
              private infoService: InfoService,
              private sailServerService: SailServerService,
              private alertService: AlertModalService,
              public base: BaseService) {
    //
  }

  async ngOnInit() {
    try {
      this.loader.visibility = true;

      this.appService.checkAuth().then();

      while (this.appService.userDetails === undefined || !this.appService.isAuth) {
        console.debug(`${LogPrefix.D} Waiting for auth...`);
        await new Promise(r => setTimeout(r, 1000));
      }

      await this.loadSailServers();

      this.user = this.appService.userDetails;
      this.setGateway();

      if (this.base.selectedGateway > 0 || this.base.selectedSailServer > 0) {
        await this.loadTracks();
      }
    } finally {
      this.loader.visibility = false;
    }
  }

  async setGatewaySelection(gatewayId: number) {
    this.base.selectedGateway = gatewayId;
    this.base.selectedSailServer = 0;
    await this.loadTracks();
  }

  async setForeignGatewaySelection(id: number) {
    this.base.selectedGateway = 0;
    this.base.selectedSailServer = id;
    await this.loadTracks();
  }

  async loadSailServers() {
    try {
      this.sailServers = await this.sailServerService.getSailServer();
    } catch (e) {
      console.warn('Failed to load sail servers', e);
    }
  }

  async loadTracks() {
    try {
      this.tracks = await this.trackService.getTracks(this.page, this.take);
      this.tracks = this.tracks.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

      this.sortedList = [];
      this.tracks.forEach(track => {
        const year = new Date(track.createdAt).getFullYear();
        const list = this.sortedList.find(l => l.year === year);
        if (list === undefined) {
          this.sortedList.push(new TrackList(year, [track]));
        } else {
          list.tracks.push(track);
        }
      });
    } catch (e) {
      console.warn('Failed to load tracks', e);
    } finally {
      this.setCountryFlags().then();
    }
  }

  setGateway() {
    if (this.user?.gateways.length === 1 && this.user.permitted_gateways.length <= 1) {
      this.base.selectedGateway = this.user.gateways[0].id;
    } else if (this.user?.permitted_gateways.length! > 0) {
      this.base.selectedGateway = this.user?.permitted_gateways[0].id!;
    } else if (this.sailServers.length > 0) {
      this.base.selectedSailServer = this.sailServers[0].id;
    }
  }

  getDate(date: Date) {
    moment.locale('de');
    return moment(date).format('L');
  }

  getTime(date: Date) {
    moment.locale('de');
    return moment(date).format('LT');
  }

  getRouteSnapshotImageUrl(track: Track) {
    return `${this.base.url}/track/snapshot/${track.routeSnapshotFile}`;
  }

  async connectSailServer() {
    if (StringHelper.isNullOrEmpty(this.sailServerApiKey) || !validator.isAlphanumeric(this.sailServerApiKey)) {
      this.alertService.show('Der API-Schlüssel enthält ungültige Zeichen.');
      return;
    }
    try {
      this.loader.visibility = true;
      await this.sailServerService.connectSailServer(this.sailServerApiKey);
      this.connectModalVisible = false;
      this.alertService.show('Die Verbindung zum SailServer wurde erfolgreich hergestellt.', 8000, AlertType.Success);
    } catch (e) {
      console.error(e);
    } finally {
      this.loader.visibility = false;
    }
  }

  async selectTrack(track: Track) {
    if (track.gateway != null) {
      await this.router.navigate(['/track', track.gateway.id, -1, track.id]);
    } else if (track.sailServer != null) {
      await this.router.navigate(['/track', -1, track.sailServer.id, track.id]);
    }
  }

  async setCountryFlags() {
    for (const trackList of this.sortedList) {
      for (const track of trackList.tracks) {
        await this.setCountryFlag(track);
      }
    }
  }

  async setCountryFlag(track: Track) {
    if (track.startCountry !== undefined) {
      track.startCountry = `${await this.loadFlag(track.startCountry)} ${track.startCountry}`;
    }
    if (track.destinationCountry !== undefined) {
      track.destinationCountry = `${track.destinationCountry} ${await this.loadFlag(track.destinationCountry)}`;
    }
  }

  async loadFlag(name: string) {
    if (this.flagDict[name] === undefined) {
      try {
        this.flagDict[name] = (await this.infoService.getCountryFlag(name)).flag;
      } catch (e) {
        this.flagDict[name] = '🌍';
      }
    }
    return this.flagDict[name];
  }

}

export class TrackList {
  constructor(public year: number, public tracks: Track[]) {
    //
  }
}
