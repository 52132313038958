import { Component } from '@angular/core';
import {LoaderService} from "./loader.service";
import {NgClass, NgIf} from "@angular/common";
import {BaseService} from "logbuch-client-sdk";
import {ProgressSpinner} from "primeng/progressspinner";

@Component({
  selector: 'app-loader',
  imports: [
    NgIf,
    ProgressSpinner
  ],
  templateUrl: './loader.component.html',
  standalone: true,
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  constructor(public service: LoaderService, public base: BaseService) {
    //
  }

}
