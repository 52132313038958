<div class="tracks-wrapper">

  <app-nmea-connect-preview *ngIf="tracks.length <= 0 && !isBusy" [sailServers]="sailServers" [hasBetaAccess]="user?.trackBeta ?? false"
                            (connectModalVisibleChanged)="connectModalVisible = $event"/>

  <div *ngIf="sailServers?.length ?? 0 > 1 || possibleGateways?.length ?? 0 > 1 || (possibleGateways?.length ?? 0 > 0 && sailServers?.length ?? 0 > 0)" class="gw-selection">
    <div class="alert">
      <img src="assets/warning.svg">
      <span>Auf dieser Seite kannst du die Aufzeichnungen deiner BlackBox einsehen, um fortzufahren wähle bitte die entsprechende BlackBox aus.</span>
    </div>
    <div class="gw-list">
      <div *ngFor="let gw of possibleGateways" (click)="setGatewaySelection(gw.id)"
           class="gw-item" [ngClass]="gateway?.id == gw.id ? 'active' : ''">
        <span *ngIf="gw?.name == null">VDR #{{ gw.id }}</span>
        <span *ngIf="gw?.name != null">{{ gw.name }}</span>
      </div>
      <div *ngFor="let sailServer of sailServers"
           class="gw-item" [ngClass]="foreignGateway?.id == sailServer.id ? 'active' : ''"
           (click)="setForeignGatewaySelection(sailServer.id)">
        SailServer: {{ sailServer.description }}
      </div>
    </div>
  </div>

  <div *ngIf="tracks.length > 0" class="journal-header">
    <div class="row" style="justify-content: space-between; align-items: center; gap: 1rem;">
      <div class="title">
        Deine Tracks
      </div>
      <div *ngIf="user?.trackBeta" (click)="connectModalVisible = true" class="new-journal">
        <i class="fa-solid fa-square-plus"></i>
        <label>Neue Verbindung</label>
      </div>
    </div>

    <div class="welcome-text">
      <p>
        An dieser Stelle findest du alle deine aufgezeichneten Tracks. Wähle einen Track aus, um die Details zu sehen.
      </p>
    </div>
  </div>

  <div class="tracks-wrapper" *ngFor="let item of sortedList">
    <span class="year">{{ item.year > 0 ? item.year : 'kein Jahr zugewiesen' }}</span>
    <span class="line underline"></span>
    <div class="tracks">
      <div *ngFor="let track of item.tracks" (click)="selectTrack(track)" class="track">
        <div class="head">
          <div class="title">
            <div class="start">
              <div class="city" [innerText]="track.startCity"></div>
              <div class="country" [innerText]="track.startCountry"></div>
            </div>
            <i class="fa-solid fa-right-long"></i>
            <div class="dest">
              <div class="city" [innerText]="track.destinationCity"></div>
              <div class="country" [innerText]="track.destinationCountry"></div>
            </div>
          </div>
        </div>
        <div class="row" style="max-height: 128px; justify-content: space-between; align-items: center">
          <div class="card-details">
            <div class="details">
              <div class="detail">
                <i class="pi pi-calendar"></i>
                <div [innerText]="getDate(track.createdAt)"></div>
              </div>
            </div>
            <div class="data-entity">
              <label>Start</label>
              <span>{{ getTime(track.createdAt) }}</span>
            </div>
            <div class="data-entity">
              <label>Ankunft</label>
              <span>{{ getTime(track.stopAt) }}</span>
            </div>
          </div>
          <img class="snapshotImage" [src]="getRouteSnapshotImageUrl(track)" alt="Map">
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog header="SailServer - Connect" [modal]="true"
          [(visible)]="connectModalVisible" [style]="{ width: '50rem' }"
          [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
  <div class="col" style="gap: 1rem;">
    <span>
      Wenn du einen SailServer bereits hast, kannst du diesen hier mit DiLog verbinden. Wir benötigen dazu deinen API-Schlüssel des entsprechenden SailServers.
    </span>
    <span>
      Unser System prüft dann in regelmäßigen Abständen, ob neue Tracks auf deinem SailServer verfügbar sind und importiert diese automatisch in dein Logbuch.
    </span>
    <div class="col" style="gap: 0.5rem;">
      <label for="key">API-Schlüssel</label>
      <input [style]="{width: '100%'}" pInputText id="key" aria-describedby="key-help" [(ngModel)]="sailServerApiKey" />
      <small id="key-help">Wir benötigen deinen API-Schlüssel vom SailServer, um auf deine Tracks zugreifen zu können.</small>
    </div>
    <div class="row" style="gap: 1rem;">
      <p-button label="Verbinden" (onClick)="connectSailServer()" [loading]="isBusy" />
    </div>
  </div>
</p-dialog>
