<div class="wrapper">
  <div class="container">
    <div class="base-item multiple">
      <img src="assets/stats/clock.svg">
      <div>
        <div>
          <label>Start</label>
          <span [innerText]="getTime(stats?.startAt!)"></span>
        </div>
        <div>
          <label>Ankunft</label>
          <span [innerText]="getTime(stats?.endAt!)"></span>
        </div>
      </div>
    </div>
    <div class="base-item simple">
      <img src="assets/stats/countdown.svg">
      <div>
        <label>Dauer</label>
        <span>{{ getDuration(stats?.startAt!, stats?.endAt!) }}</span>
      </div>
    </div>
    <div class="base-item simple">
      <img src="assets/stats/route.svg">
      <div>
        <label>Strecke</label>
        <span>{{ stats?.distance?.toFixed(0) }} NM</span>
      </div>
    </div>
    <div *ngIf="stats?.maxWindSpeed !== undefined && stats?.windSpeedAverage !== undefined" class="base-item multiple">
      <img src="assets/stats/wind.svg">
      <div>
        <div>
          <label>MAX</label>
          <span>{{ stats?.maxWindSpeed?.toFixed(1) }} KN</span>
        </div>
        <div>
          <label>Ø</label>
          <span>{{ stats?.windSpeedAverage?.toFixed(1) }} KN</span>
        </div>
      </div>
    </div>
    <div *ngIf="stats?.maxTemperature !== undefined && stats?.minTemperature !== undefined" class="base-item multiple">
      <img src="assets/stats/thermometer.svg">
      <div>
        <div>
          <label>MAX</label>
          <span>{{ convertTemperature(stats?.maxTemperature!).toFixed(1) }} °C</span>
        </div>
        <div>
          <label>MIN</label>
          <span>{{ convertTemperature(stats?.minTemperature!).toFixed(1) }} °C</span>
        </div>
      </div>
    </div>
    <div class="base-item multiple">
      <img src="assets/stats/speedometer.svg">
      <div>
        <div>
          <label>MAX</label>
          <span>{{ convertSpeed(stats?.maxSpeed!) }} KN</span>
        </div>
        <div>
          <label>Ø</label>
          <span>{{ stats?.speedAverage?.toFixed(1) }} KN</span>
        </div>
      </div>
    </div>
    <div class="base-item special">
      <img src="assets/stats/microphone.svg">
      <label>Spracheinträge</label>
      <span [innerText]="stats?.voiceEntries ?? '-/-'"></span>
    </div>
    <div class="base-item special">
      <img src="assets/stats/pen.svg">
      <label>Logbucheinträge</label>
      <span [innerText]="stats?.manualEntries ?? '-/-'"></span>
    </div>
  </div>
  <div *ngIf="stats == null" class="loader-wrapper animate__animated animate__fadeIn">
    <span class="loader"></span>
  </div>
</div>
