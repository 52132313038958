import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Button} from "primeng/button";
import {FormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";
import {SailServer} from "logbuch-client-sdk";

@Component({
  selector: 'app-nmea-connect-preview',
  imports: [
    Button,
    Button,
    FormsModule,
    NgIf,
  ],
  templateUrl: './nmea-connect-preview.component.html',
  standalone: true,
  styleUrl: './nmea-connect-preview.component.scss'
})
export class NmeaConnectPreviewComponent {

  @Input() sailServers: SailServer[] = [];
  @Input() hasBetaAccess = false;

  @Output() connectModalVisibleChanged = new EventEmitter<boolean>();

  constructor() {
    //
  }
}
